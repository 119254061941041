import React from "react"
import { graphql } from "gatsby"
import Layout from "wildComponents/Layout"
import { Seo } from "wildComponents/seo/components"
import LPForm from "../sections/shared/LPForm"
import LPHero from "../sections/shared/LPHero"
import LPHowMuch from "../sections/shared/LPHowMuch"
import LPRequirements from "../sections/shared/LPRequirements"
import DarkGroup from "../components/common/DarkGroup"
import TwoColumnText from "../sections/shared/TwoColumn_Text"
import LPContact from "../sections/shared/LPContact"
import Perks from "../sections/page-specific/home/Perks"
import PerksText from "../sections/page-specific/home/PerksText"
import LPHeadingLeft from "../sections/shared/LPHeadingLeft"

export const Head = ({ location, data, pageContext }) => (
  <Seo ctx={pageContext} data={data} location={location} />
)

function LandingPage1(props) {
  return (
    <Layout>
      <LPHero data={props.data.wpPage.acf_hero} />
      <LPForm data={props.data.wpPage.acf_form} />
      <DarkGroup>
        <LPHowMuch data={props.data.wpPage.acf_cost_calculator} />
        <LPHeadingLeft data={props.data.wpPage.acf_headingleft} />
      </DarkGroup>
      <TwoColumnText data={props.data.wpPage.acf_twoColumnText} />
      <LPRequirements data={props.data.wpPage.acf_requirements} />
      <DarkGroup>
        <PerksText />
        <Perks />
      </DarkGroup>
      <LPContact />
    </Layout>
  )
}

export default LandingPage1

export const pageQuery = graphql`
  query ($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      ...HERO_DATA
      ...LP_FORM_DATA
      ...CC_DATA
      ...TWO_COL_DATA
      ...REQUIREMENTS_DATA
      ...HEADING_LEFT_DATA
    }
  }
`
