import React from "react"
import { graphql } from "gatsby"
import Section from "wildComponents/Section"
import { Heading, Stack, Text, VStack, useTheme } from "@chakra-ui/react"

export const query = graphql`
  fragment TWO_COL_DATA on WpPage {
    acf_twoColumnText {
      text12c
      text22c
      title2c
    }
  }
`

function TwoColumnText({ data }) {
  const { variables } = useTheme()
  const { componentSpacing } = variables
  const dataFromWp = !!data.text12c || !!data.text22c || !!data.title2c

  return dataFromWp ? (
    <Section bg="dark.default" containerSize="lg" zIndex={["2", "2", "unset"]}>
      <VStack spacing={componentSpacing} align="flex-start">
        <Heading
          className="jumbo"
          color="white"
          maxW="100%"
          dangerouslySetInnerHTML={{ __html: data.title2c }}
        />
        <Stack
          direction={["column", "column", "row"]}
          align="flex-start"
          spacing={[8, 8, 20]}
        >
          <Heading as="h3" className="thin-h3" color="white" flex="1">
            {data.text12c}
          </Heading>
          <VStack flex="1" spacing={5} align="flex-start">
            <Text
              color="white"
              dangerouslySetInnerHTML={{ __html: data.text22c }}
            />
          </VStack>
        </Stack>
      </VStack>
    </Section>
  ) : (
    <></>
  )
}

export default TwoColumnText
