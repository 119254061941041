import React from "react"
import { graphql } from "gatsby"

import Section from "wildComponents/Section"
import { Link } from "gatsby"
import { Button, Heading, Stack, VStack } from "@chakra-ui/react"

export const query = graphql`
  fragment HEADING_LEFT_DATA on WpPage {
    acf_headingleft {
      textRight
      headingLeft
      button {
        buttonText
        link
      }
    }
  }
`

function LPHeadingLeft({ data }) {
  const dataFromWp = !!data.headingLeft || !!data.textRight
  return dataFromWp ? (
    <Section className="light" containerSize="xl">
      <Stack
        direction={["column", "column", "row"]}
        spacing={[8, 8, 40]}
        position="relative"
      >
        <Heading
          flex="2"
          className="jumbo-custom"
          dangerouslySetInnerHTML={{ __html: data.headingLeft }}
        />

        <VStack spacing={12} align="start" flex="1" position="relative">
          <Heading as="h3" className="thin-h3" color="dark.default">
            {data.textRight}
          </Heading>

          {data.button.link && (
            <Link to={data.button.link}>
              <Button>{data.button.buttonText}</Button>
            </Link>
          )}
        </VStack>
      </Stack>
    </Section>
  ) : (
    <></>
  )
}

export default LPHeadingLeft
